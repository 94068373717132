<template>
  <div
    class="w-full max-w-[400px] h-[40px] flex flex-row items-center border border-neutral-500 border-solid px-[12px] py-[10px] rounded-lg gap-4"
  >
    <VIcon name="search-line" :size="-5" />
    <input
      v-model="query"
      class="text-base text-neutral-600 w-full border-0 focus-visible:outline-none"
      :placeholder="$t('searchForAnyKeyword')"
      autocomplete="off"
    />
    <VIcon v-show="query" name="close-small-line" class="cursor-pointer" :size="-4" @click="query = ''" />
  </div>
</template>

<script setup lang="ts">
import { defineModel, ref, watch } from 'vue';
import { debounce } from 'lodash-es';
import { VIcon } from '@vonage/vivid-vue';

const model = defineModel();
const query = ref(model.value);

// bake in debounce into the field so that value is emitted only
// when the user has finished typing
const updateModel = debounce(() => {
  model.value = query.value;
}, 200);

watch(query, updateModel);
watch(model, () => {
  if (model.value !== query.value) query.value = model.value;
});
</script>
