<template>
  <div class="flex flex-col justify-center p-[32px] lg:p-[64px] gap-[32px] lg:gap-[64px]">
    <div class="flex flex-row justify-center">
      <BlogArticleViewSearch v-model="searchText" />
    </div>
    <div class="flex flex-row justify-center">
      <div v-if="articles.length > 0" class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-[48px]">
        <BlogArticleCard
          v-for="article in articles"
          :key="article._uid"
          :article="article"
          :pending="pending"
          :author="!authorUuid"
        />
      </div>
      <NoBlogPostPlaceholder v-else />
    </div>
    <div v-if="pages > 1" class="flex justify-center pb-[108px] sm:pb-[140px] pt-0 px-0">
      <VPagination
        id="pagination"
        :total="pages"
        :selected-index="page - 1"
        @pagination-change="event => (page = event.detail.selectedIndex + 1)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { useArticlesApi } from '@/utils/blog/articles';
import BlogArticleCard from '@/components/blog/BlogArticleCard.vue';
import BlogArticleViewSearch from '@/components/blog/BlogArticleViewSearch.vue';
import { VPagination } from '@vonage/vivid-vue';
import NoBlogPostPlaceholder from '@/components/blog/NoBlogPostPlaceholder.vue';

const props = defineProps({
  authorUuid: String,
  pageSize: Number,
});

const { articles, searchText, pending, page, pages } = await useArticlesApi({
  ...props,
});

// reset pagination when search text changes
watch(
  () => [searchText.value],
  () => {
    if (page.value > 1) page.value = 1;
  }
);
</script>
