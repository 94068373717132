<template>
  <div class="flex flex-col justify-center items-center max-w-[448px] my-12">
    <StoryblokImage :image="story.content.image" :width="289" :height="215" />
    <h4>{{ story.content.title }}</h4>
    <RichText :text="story.content.description" class="mt-0" />
  </div>
</template>

<script setup lang="ts">
import { useBrandingStore } from '@/store/branding';
import useAsyncStoryblokWithI18n from '@/utils/storyblok/useAsycStoryblokWithI18n';
import type { NoBlogPostPlaceholderStory } from '@/types/storyblok';
import StoryblokImage from '@/components/storyblok/StoryblokImage.vue';
import RichText from '@/components/rich-text/RichText.vue';

const brandingStore = useBrandingStore();

const story: NoBlogPostPlaceholderStory = await useAsyncStoryblokWithI18n(
  `branding/${brandingStore.data?.vendorId}/config/blog/no-blog-post-placeholder`
);
</script>
